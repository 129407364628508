import React, { useState, useEffect } from "react";
import axios from "axios";
import logo from "../images/logos/gftlogodark.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { setAccountPopUpVisibility } from "../redux/reducers/accountPopup";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { handleContactDataChange } from "../redux/reducers/contactData";
import { tripDetails } from "../data/tripDetails";
import LoaderFs from "../components/loader/LoaderFs";

const toastOptions = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "dark",
};

const AccountPopUp = () => {
  let accountPopupState = useSelector((state) => state.accountpopup);

  const data = useSelector((state) => state.contactdata);

  const [getLocationBtnText, setGetLocationBtnText] =
    useState("Provide Location");

  useEffect(() => {
    if (accountPopupState) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [accountPopupState]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(1);

  const [locationPermission, setLocationPermission] = useState(true);

  const [isPasswordSame, setIsPasswordSame] = useState(true);

  const handleLocationPermission = () => {
    if (locationPermission) {
      getLocation();
    } else {
      console.log("locations denied");
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(showPosition, handleLocationError, {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 5000,
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const showPosition = async (position) => {
    const { latitude, longitude } = position.coords;
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
      );
      if (response.data.address) {
        handleChange(
          null,
          "userLocation",
          response.data.address.state_district
        );
        setGetLocationBtnText(response.data.address.state_district);
      } else {
        toast.error("please provide the location", toastOptions);
        setLocationPermission(false);
      }
    } catch (error) {
      toast.error("please provide the location", toastOptions);
      setLocationPermission(false);
    }
  };

  function handleLocationError(error) {
    // Handle the error when the user denies or the location cannot be obtained
    if (error.code === error.PERMISSION_DENIED) {
      console.log("User denied the request for Geolocation.");
      setLocationPermission(false);
      // You can provide a message here or trigger another attempt to request location access
    } else if (error.code === error.POSITION_UNAVAILABLE) {
      console.log("Location information is unavailable.");
    } else if (error.code === error.TIMEOUT) {
      console.log("The request to get user location timed out.");
    } else if (error.code === error.UNKNOWN_ERROR) {
      console.log("An unknown error occurred.");
    }
  }

  const handleChange = (e, fieldname = "", fieldvalue = "") => {
    let name = fieldname === "" ? e.target.name : fieldname;
    let value = fieldvalue === "" ? e.target.value : fieldvalue;
    dispatch(
      handleContactDataChange({
        name: name,
        value: value,
      })
    );
  };

  const passwordCheck = (e) => {
    if (data.password === e.target.value) {
      setIsPasswordSame(true);
    } else {
      setIsPasswordSame(false);
    }
  };

  const handleManualValidation = (e) => {
    e.preventDefault();
    if (data.name === "") {
      toast.error("please enter name", toastOptions);
      return;
    }
    if (data.email === "") {
      toast.error("please enter email", toastOptions);
      return;
    }
    if (data.phone === "") {
      toast.error("please enter phone", toastOptions);
      return;
    }
    if (data.password === "") {
      toast.error("please enter password", toastOptions);
      return;
    }
    if (data.userLocation === "") {
      toast.error("please enter location", toastOptions);
      return;
    }
    handleSubmition("register", data);
  };

  const handleGoogleRegistrationValidation = (e) => {
    e.preventDefault();

    if (data.phone === "") {
      toast.error("please enter phone", toastOptions);
      return;
    }

    if (data.userLocation === "") {
      toast.error("please enter location", toastOptions);
      return;
    }
  };

  const handleLoginValidation = (e) => {
    e.preventDefault();

    if (data.email === "") {
      toast.error("please enter email", toastOptions);
      return;
    }

    if (data.password === "") {
      toast.error("please enter password", toastOptions);
      return;
    }

    handleSubmition("login", data);
  };

  const [route, setRoute] = useState("");

  useEffect(() => {
    if (data.googleAccessToken !== "" && route !== "") {
      handleSubmition(route, data);
    }
  }, [route, data.googleAccessToken]);

  const handleToken = (token) => {
    localStorage.setItem("gft_token", token);
    dispatch(setAccountPopUpVisibility(false));
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmition = async (api, data) => {
    setIsLoading(true);

    let res = await axios.post(
      `https://gftapi.onrender.com/auth/${api}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    setIsLoading(false);

    if (res.data.userAlreadyExists) {
      toast.warn("user already exists", toastOptions);
      setSelectedTab(2);
    } else if (res.data.userCreated) {
      toast.success("welcome to the club!", toastOptions);
      handleToken(await res.data.token);
    } else if (res.data.userDoesNotExist) {
      toast.error("user does not exists", toastOptions);
    } else if (res.data.token) {
      toast.success("Welcome back!", toastOptions);
      handleToken(await res.data.token);
    } else if (res.data.invalidPassword) {
      toast.error("Invalid Password", toastOptions);
    } else if (res.data.serverError) {
      toast.error("Please try again or contact admin!", toastOptions);
    } else if (res.data.notGoogleAccount) {
      toast.error("Please signin using password", toastOptions);
    } else if (!res.data.userCreated) {
      toast.error("Please try again!", toastOptions);
    }
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <div
      className={`h-screen w-full bg-[#ffffffb6] fixed top-0 left-0 z-[9999] ${
        accountPopupState ? "flex" : "hidden"
      } flex-col justify-center items-center backdrop-blur-sm gap-4`}
    >
      {isLoading && <LoaderFs />}
      <i
        className="fa-solid fa-xmark absolute top-5 right-5 text-4xl duration-100 hover:text-5xl cursor-target drop-shadow-lg text-primary"
        onClick={() => {
          navigate(-1);
          dispatch(setAccountPopUpVisibility(false));
        }}
      ></i>
      <div className="flex justify-center items-center gap-4 cursor-target">
        <button
          className={`px-4 py-1 rounded-full cursor-target ${
            selectedTab === 0
              ? "shadow-lg bg-primary text-secondary"
              : "bg-secondary text-primary"
          }`}
          onClick={() => handleTabChange(0)}
        >
          SignUp
        </button>
        <button
          className={`px-4 py-1 rounded-full cursor-target ${
            selectedTab === 1
              ? "shadow-lg bg-primary text-secondary"
              : "bg-secondary text-primary"
          }`}
          onClick={() => handleTabChange(1)}
        >
          SignUp with Google
        </button>
        <button
          className={`px-4 py-1 rounded-full cursor-target ${
            selectedTab === 2
              ? "shadow-lg bg-primary text-secondary"
              : "bg-secondary text-primary"
          }`}
          onClick={() => handleTabChange(2)}
        >
          Login
        </button>
      </div>
      <div className="min-h-[80%] w-full flex justify-center items-center">
        {selectedTab === 0 && (
          <form
            className="flex flex-col justify-center items-center gap-4 w-full"
            onSubmit={handleManualValidation}
          >
            <div className="h-[10vh]">
              <Link
                to={"/"}
                onClick={() => dispatch(setAccountPopUpVisibility(false))}
              >
                <img
                  loading="lazy"
                  src={logo}
                  alt="logo"
                  className="h-full w-full object-contain object-center drop-shadow-lg"
                />
              </Link>
            </div>
            <p className="capitalize">please signup to continue</p>
            <input
              className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6 lg:w-1/5 shadow-black shadow-sm"
              onChange={handleChange}
              type="text"
              name="name"
              value={data.name}
              placeholder="name *"
            />

            <input
              className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6 lg:w-1/5 shadow-black shadow-sm"
              onChange={handleChange}
              type="email"
              name="email"
              value={data.email}
              placeholder="email *"
            />

            <input
              className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6 lg:w-1/5 shadow-black shadow-sm"
              onChange={handleChange}
              type="number"
              name="phone"
              value={data.phone}
              placeholder="phone *"
            />

            <input
              className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6 lg:w-1/5 shadow-black shadow-sm"
              onChange={handleChange}
              type="password"
              name="password"
              placeholder="password *"
            />

            <input
              className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6 lg:w-1/5 shadow-black shadow-sm"
              onChange={passwordCheck}
              type="password"
              name="confirmPassword"
              placeholder="confirmPassword *"
            />

            {!isPasswordSame && (
              <p className="text-red-500">
                Password and confirm password must be the same
              </p>
            )}
            {locationPermission ? (
              <button
                className="btn-primary py-2 px-4 border border-primary shadow-black shadow-sm disabled:bg-gray-400 disabled:text-white"
                onClick={handleLocationPermission}
                type="button"
                disabled={getLocationBtnText !== "Provide Location"}
              >
                {getLocationBtnText}&nbsp;&nbsp;
                {getLocationBtnText !== "Provide Location" && (
                  <i className="fa-solid fa-check text-green-400"></i>
                )}
              </button>
            ) : (
              <input
                className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6 lg:w-1/5 shadow-black shadow-sm"
                onChange={handleChange}
                type="text"
                name="userLocation"
                value={data.userLocation}
                placeholder="current location *"
              />
            )}

            <button
              className="btn-primary py-2 px-4 border border-primary shadow-black shadow-sm"
              type="submit"
            >
              submit
            </button>
          </form>
        )}
        {selectedTab === 1 && (
          <div className="flex flex-col justify-center items-center gap-4 w-full">
            <form
              className="flex flex-col justify-center items-center gap-4 w-full"
              onSubmit={handleGoogleRegistrationValidation}
            >
              <div className="h-[10vh]">
                <Link to={"/"}>
                  <img
                    loading="lazy"
                    src={logo}
                    alt="logo"
                    className="h-full w-full object-contain object-center drop-shadow-lg"
                  />
                </Link>
              </div>
              <p className="capitalize">please signup to continue</p>
              <input
                className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6 lg:w-1/5 shadow-black shadow-sm"
                onChange={handleChange}
                type="number"
                name="phone"
                value={data.phone}
                placeholder="phone *"
              />
              {!locationPermission && (
                <input
                  className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6 lg:w-1/5 shadow-black shadow-sm"
                  onChange={handleChange}
                  type="text"
                  name="userLocation"
                  placeholder="current location *"
                  value={data.userLocation}
                />
              )}
            </form>
            {locationPermission && (
              <button
                className="btn-primary py-2 px-4 border border-primary shadow-black shadow-sm disabled:bg-gray-400 disabled:text-white"
                onClick={handleLocationPermission}
                disabled={getLocationBtnText !== "Provide Location"}
              >
                {getLocationBtnText}&nbsp;&nbsp;
                {getLocationBtnText !== "Provide Location" && (
                  <i className="fa-solid fa-check text-green-400"></i>
                )}
              </button>
            )}
            <div className="relative overflow-hidden">
              <div
                onClick={handleGoogleRegistrationValidation}
                className={`absolute h-full w-full z-10 rounded-sm ${
                  data.phone !== "" && data.userLocation !== ""
                    ? "pointer-events-none"
                    : "bg-[#0007]"
                }`}
              ></div>
              <GoogleLogin
                onSuccess={(googleAccessToken) => {
                  handleChange(null, "googleAccessToken", googleAccessToken);
                  setRoute("googleSignup");
                }}
                onError={() => {
                  toast.error("please signup to continue", toastOptions);
                }}
              />
            </div>
          </div>
        )}
        {selectedTab === 2 && (
          <div className="w-full flex flex-col justify-center items-center gap-4">
            <form
              className="flex flex-col justify-center items-center gap-4 w-full"
              onSubmit={handleLoginValidation}
            >
              <div className="h-[10vh]">
                <img
                  loading="lazy"
                  src={logo}
                  alt="logo"
                  className="h-full w-full object-contain object-center drop-shadow-lg"
                />
              </div>
              <p className="capitalize">please login to continue</p>
              <input
                className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6 lg:w-1/5 shadow-black shadow-sm"
                onChange={handleChange}
                type="email"
                name="email"
                value={data.email}
                placeholder="email *"
              />

              <input
                className="p-2 rounded-lg border border-primary focus:outline-primary placeholder:capitalize w-4/5 sm:w-3/6 lg:w-1/5 shadow-black shadow-sm"
                onChange={handleChange}
                type="password"
                name="password"
                placeholder="password *"
              />

              <button
                className="btn-primary py-2 px-4 border border-primary shadow-black shadow-sm"
                type="submit"
              >
                submit
              </button>

              <p>or</p>
            </form>
            <GoogleLogin
              onSuccess={(googleAccessToken) => {
                handleChange(null, "googleAccessToken", googleAccessToken);
                setRoute("googleLogin");
              }}
              onError={() => {
                toast.error("please signup to continue", toastOptions);
              }}
              // onSuccess={async (data) => {
              //   let token = await data.credential;
              //   let res = await axios.post(
              //     "http://192.168.1.18:5000/auth/googleLogin",
              //     { googleAccessToken: token },
              //     {
              //       headers: {
              //         "Content-Type": "application/json",
              //       },
              //     }
              //   );
              // }}
              // onError={() => {
              //   toast.error("please signup to continue", toastOptions);
              // }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountPopUp;

// import React from "react";
// import { useParams } from "react-router-dom";
// import { tripDetails, tripLocations } from "../../data/tripDetails";
// import { useEffect } from "react";
// import { useState } from "react";

// const Trips = () => {
//   const { days } = useParams();
//   const [data, setData] = useState(null);

//   useEffect(() => {
//     const filteredTrips = tripLocations.filter((trip) => {
//       if (tripDetails[trip].category === days) {
//         return trip;
//       }
//     });
//     const filteredTripsData = filteredTrips.map((trip) => {
//       return tripDetails[trip];
//     });
//     setData(filteredTripsData);
//   }, [days]);

//   useEffect(() => {
//     console.log(data);
//   }, [data]);

//   return <div>{days} Trips</div>;
// };

// export default Trips;

// const Card = ({ data, index }) => {
//   return (
//     <div
//       className={`h-[50vh] w-4/5 sm:w-3/6 lg:w-1/4 bg-red-400 rounded-lg bg-cover bg-center flex flex-col justify-between items-center p-2`}
//       style={{ backgroundImage: `url(${data.image ? data.image : img})` }}
//       key={index}
//     >
//       <h2 className="capitalize text-primary font-heading text-2xl tracking-wider bg-secondary p-2 rounded-lg w-full text-center">
//         {data.location}
//       </h2>
//       <Link to={data.link} onClick={() => window.scrollTo(0, 0)}>
//         <button className="cursor-target btn-primary p-2 text-lg font-bold">
//           itinerary
//         </button>
//       </Link>
//     </div>
//   );
// };

// const Trips = () => {
//   const { days } = useParams();
//   const [data, setData] = useState({});

//   useEffect(() => {
//     setData(trips[days]);
//   }, [days]);

//   return (
//     <div className="flex flex-col items-center justify-center h-auto w-full font-content relative z-50 bg-secondary space-y-10 p-2 pb-10">
//       <div
//         className="flex flex-col justify-evenly items-center text-center h-[20vh] w-11/12 bg-contain bg-center bg-no-repeat"
//         style={{ backgroundImage: `url(${bannerImg})` }}
//       >
//         <h1 className="backdrop-blur-sm text-primary text-4xl md:text-8xl font-heading capitalize drop-shadow-md">
//           {data.name}
//         </h1>
//         <h2 className="backdrop-blur-sm text-primary text-2xl md:text-3xl font-bold drop-shadow-md">
//           Exclusive excursions
//         </h2>
//       </div>

//       <p className="text-lg md:text-2xl w-4/5 text-center text-primary">
//         We offer carefully crafted domestic travel itineraries for people of all
//         ages, enabling them to get the most out of their time in India. Our
//         industry-leading travel services include lodging, flights, meals, and
//         on-site assistance from our trip leader to ensure the best possible
//         travel experience.
//       </p>
//       {data.cards &&
//         data.cards.map((cardData, index) => {
//           return <Card data={cardData} key={index} index={index} />;
//         })}
//     </div>
//   );
// };

// export default Trips;
