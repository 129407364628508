import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="space-y-4 p-1 xl:p-20 xl:pt-10">
      <div className="shadow-sm rounded-md shadow-slate-400 gap-4 h-[30vh] w-full flex flex-col justify-center items-center bg-center bg-cover bg-[url(https://images.unsplash.com/photo-1523878288860-7ad281611901?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1171&q=80)]">
        <h1 className="text-4xl xl:text-6xl text-center ">
          Terms and Conditions <br />{" "}
          <span className="text-xl">for Go Foot Travels</span>
        </h1>
      </div>
      <div className="space-y-4 border-slate-200 border p-2 rounded-md shadow-lg">
        <h2 className="font-medium text-2xl">Introduction</h2>

        <p>
          These Website Standard Terms and Conditions written on this webpage
          shall manage your use of our website, Go Foot Travels accessible at
          &nbsp;
          <a href="https://www.gofoottravels.in/" className="text-blue-600">
            https://www.gofoottravels.in/
          </a>
          .
        </p>

        <p>
          These Terms will be applied fully and affect to your use of this
          Website. By using this Website, you agreed to accept all terms and
          conditions written in here. You must not use this Website if you
          disagree with any of these Website Standard Terms and Conditions.
        </p>

        <p>
          Minors or people below 18 years old are not allowed to use this
          Website.
        </p>

        <h2 className="font-medium text-2xl">Intellectual Property Rights</h2>

        <p>
          Other than the content you own, under these Terms, Go Foot Travels
          and/or its licensors own all the intellectual property rights and
          materials contained in this Website.
        </p>

        <p>
          You are granted limited license only for purposes of viewing the
          material contained on this Website.
        </p>

        <h2 className="font-medium text-2xl">Restrictions</h2>

        <p>You are specifically restricted from all of the following:</p>

        <ul className="space-y-2">
          <li>publishing any Website material in any other media;</li>
          <li>
            selling, sublicensing and/or otherwise commercializing any Website
            material;
          </li>
          <li>publicly performing and/or showing any Website material;</li>
          <li>
            using this Website in any way that is or may be damaging to this
            Website;
          </li>
          <li>
            using this Website in any way that impacts user access to this
            Website;
          </li>
          <li>
            using this Website contrary to applicable laws and regulations, or
            in any way may cause harm to the Website, or to any person or
            business entity;
          </li>
          <li>
            engaging in any data mining, data harvesting, data extracting or any
            other similar activity in relation to this Website;
          </li>
          <li>using this Website to engage in any advertising or marketing.</li>
        </ul>

        <p>
          Certain areas of this Website are restricted from being access by you
          and Go Foot Travels may further restrict access by you to any areas of
          this Website, at any time, in absolute discretion. Any user ID and
          password you may have for this Website are confidential and you must
          maintain confidentiality as well.
        </p>

        <h2 className="font-medium text-2xl">Your Content</h2>

        <p>
          In these Website Standard Terms and Conditions, "Your Content" shall
          mean any audio, video text, images or other material you choose to
          display on this Website. By displaying Your Content, you grant Go Foot
          Travels a non-exclusive, worldwide irrevocable, sub licensable license
          to use, reproduce, adapt, publish, translate and distribute it in any
          and all media.
        </p>

        <p>
          Your Content must be your own and must not be invading any
          third-party's rights. Go Foot Travels reserves the right to remove any
          of Your Content from this Website at any time without notice.
        </p>

        <h2 className="font-medium text-2xl">No warranties</h2>

        <p>
          This Website is provided "as is," with all faults, and Go Foot Travels
          express no representations or warranties, of any kind related to this
          Website or the materials contained on this Website. Also, nothing
          contained on this Website shall be interpreted as advising you.
        </p>

        <h2 className="font-medium text-2xl">Limitation of liability</h2>

        <p>
          In no event shall Go Foot Travels, nor any of its officers, directors
          and employees, shall be held liable for anything arising out of or in
          any way connected with your use of this Website whether such liability
          is under contract.  Go Foot Travels, including its officers, directors
          and employees shall not be held liable for any indirect, consequential
          or special liability arising out of or in any way related to your use
          of this Website.
        </p>

        <h2 className="font-medium text-2xl">Indemnification</h2>

        <p>
          You hereby indemnify to the fullest extent Go Foot Travels from and
          against any and/or all liabilities, costs, demands, causes of action,
          damages and expenses arising in any way related to your breach of any
          of the provisions of these Terms.
        </p>

        <h2 className="font-medium text-2xl">Severability</h2>

        <p>
          If any provision of these Terms is found to be invalid under any
          applicable law, such provisions shall be deleted without affecting the
          remaining provisions herein.
        </p>

        <h2 className="font-medium text-2xl">Variation of Terms</h2>

        <p>
          Go Foot Travels is permitted to revise these Terms at any time as it
          sees fit, and by using this Website you are expected to review these
          Terms on a regular basis.
        </p>

        <h2 className="font-medium text-2xl">Assignment</h2>

        <p>
          The Go Foot Travels is allowed to assign, transfer, and subcontract
          its rights and/or obligations under these Terms without any
          notification. However, you are not allowed to assign, transfer, or
          subcontract any of your rights and/or obligations under these Terms.
        </p>

        <h2 className="font-medium text-2xl">Entire Agreement</h2>

        <p>
          These Terms constitute the entire agreement between Go Foot Travels
          and you in relation to your use of this Website, and supersede all
          prior agreements and understandings.
        </p>

        <h2 className="font-medium text-2xl">Governing Law & Jurisdiction</h2>

        <p>
          These Terms will be governed by and interpreted in accordance with the
          laws of the State of in, and you submit to the non-exclusive
          jurisdiction of the state and federal courts located in in for the
          resolution of any disputes.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
