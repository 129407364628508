import { createSlice } from "@reduxjs/toolkit";

const popUpSlice = createSlice({
  name: "popup",
  initialState: false,
  reducers: {
    setPopUpVisibility: (state, actions) => actions.payload,
  },
});

export const { setPopUpVisibility } = popUpSlice.actions;

export default popUpSlice.reducer;
