import { createSlice } from "@reduxjs/toolkit";

const contactDataSlice = createSlice({
  name: "contactdata",

  initialState: {
    name: "",
    email: "",
    location: "",
    contact: "",
    message: "",
    checkInDate: "",
    checkOutDate: "",
    userLocation: "",
    phone: "",
    googleAccessToken: "",
  },

  reducers: {
    handleContactDataChange: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
  },
});

export const { handleContactDataChange } = contactDataSlice.actions;

export default contactDataSlice.reducer;
