import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loader from "./components/loader/Loader";
import PopUP from "./components/PopUP";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import gftlogo from './images/logos/gftlogowithbg.png';
import ScrollToTop from "./components/ScrollToTop";
import AnimatedCursor from "./components/AnimatedCursor";
import Reveal from "./components/Reveal";
import MustHaveTripsIndividualPackage from "./pages/tripDetails/MustHaveTripsIndividualPackage";
import MustHaveTrips from "./pages/trips/MustHaveTrips";
import AccountPopUp from "./components/AccountPopUp";
import CheckAuth from "./components/CheckAuth";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "./redux/reducers/userInfo";
import { handleContactDataChange } from "./redux/reducers/contactData";

import jwtDecode from "jwt-decode";
import PirivacyPolicy from "./pages/PirivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import FromCeo from "./pages/FromCeo";
import Disclaimer from "./pages/Disclaimer";

// import NewFooter from "./components/NewFooter";

// Lazy-loaded pages
const Home = lazy(() => import("./pages/home/Home"));
const About = lazy(() => import("./pages/About"));
const Login = lazy(() => import("./pages/Login"));
const Register = lazy(() => import("./pages/Register"));
const Logout = lazy(() => import("./components/Logout"));
const TripDetails = lazy(() => import("./pages/tripDetails/TripDetails"));
const Trips = lazy(() => import("./pages/trips/Trips"));

// Lazy-loaded components
const Navbar = lazy(() => import("./components/Navbar"));
const FormAndFooter = lazy(() => import("./components/FormAndFooter"));

const toastOptions = {
  position: "top-right",
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: true,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "dark",
  autoClose: false,
};

const toastOptions2 = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: true,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "dark",
};

function App() {
  // useEffect(() => {
  //   const userAgent = navigator.userAgent.toLowerCase();
  //   const isIOS = /ipad/.test(userAgent);
  //   const isWindows = /windows/.test(userAgent);

  //   if (isWindows || isIOS) {
  //     let noti = Notification.requestPermission();

  //     var options = {
  //       body: 'DISCOVER HIDDEN GEMS AND UNIQUE DESTINATIONS!',
  //       icon: gftlogo,
  //       dir: 'ltr',
  //     };

  //     if (noti) {
  //       new Notification('Welcome to GFT', options)
  //     }
  //   }
  // }, [])

  const dispatch = useDispatch();
  let accountPopupState = useSelector((state) => state.accountpopup);

  useEffect(() => {
    (async () => {
      let token = await localStorage.getItem("gft_token");
      const decodedToken = jwtDecode(token).userDetails;
      dispatch(setUserInfo(decodedToken));
    })();
  }, [accountPopupState]);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      if (window.navigator.onLine) {
        toast.success("Back Online :)", toastOptions2);
      } else {
        toast.info("You are offline :(", toastOptions);
      }
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    // Cleanup the event listeners when the component unmounts
    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  return (
    <div className="App">
      <Router>
        <Suspense fallback={<Loader />}>
          <PopUP />
          <AccountPopUp />
          <AnimatedCursor targetClassname={"cursor-target"} />
          <Navbar />
          <ScrollToTop />
        </Suspense>
        <Routes>
          <Route
            index
            element={
              <Suspense fallback={<Loader />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path="/about"
            element={
              <Suspense fallback={<Loader />}>
                {/* <Reveal> */}
                <About />
                {/* </Reveal> */}
              </Suspense>
            }
          />
          <Route
            path="/trips/:days"
            element={
              <Suspense fallback={<Loader />}>
                {/* <Reveal> */}
                <Trips />
                {/* </Reveal> */}
              </Suspense>
            }
          />
          <Route
            path="/trip/:location"
            element={
              <Suspense fallback={<Loader />}>
                {/* <Reveal> */}
                {/* <CheckAuth> */}
                <TripDetails />
                {/* </CheckAuth> */}
                {/* </Reveal> */}
              </Suspense>
            }
          />
          {/* <Route
            path="/mht"
            element={
              <Suspense fallback={<Loader />}>
                <Reveal>
                <CheckAuth>
                  <MustHaveTripsIndividualPackage />
                </CheckAuth>
                </Reveal>
              </Suspense>
            }
          /> */}
          {/* <Route
            path="/mht/packages"
            element={
              <Suspense fallback={<Loader />}>
                <MustHaveTrips />
              </Suspense>
            }
          /> */}
          <Route
            path="/privacy-policy"
            element={
              <Suspense fallback={<Loader />}>
                <PirivacyPolicy />
              </Suspense>
            }
          />
          <Route
            path="/terms-and-conditions"
            element={
              <Suspense fallback={<Loader />}>
                <TermsAndConditions />
              </Suspense>
            }
          />
          <Route
            path="/disclaimer"
            element={
              <Suspense fallback={<Loader />}>
                <Disclaimer />
              </Suspense>
            }
          />
          <Route
            path="/from-our-ceo"
            element={
              <Suspense fallback={<Loader />}>
                <FromCeo />
              </Suspense>
            }
          />
        </Routes>
        {/* <div className="h-[90vh] md:h-[40vh] w-full bg-transparent"></div> */}
        <Suspense fallback={<Loader />}>
          {/* <NewFooter /> */}
          <FormAndFooter />
        </Suspense>
        <ToastContainer />
      </Router>
    </div>
  );
}

export default App;
