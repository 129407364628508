//mandavgarh trip images
import Mandavgarhimage from "../images/backgrounds/trips/1DayTrips/1. Mandavgarh/Mandavgarh-1.jpeg";
import Mandavgarhimage2 from "../images/backgrounds/trips/1DayTrips/1. Mandavgarh/Mandavgarh-2.jpeg";
import Mandavgarhimage3 from "../images/backgrounds/trips/1DayTrips/1. Mandavgarh/Mandavgarh-3.jpeg";

// Hanumantiya trip images
import Hanumantiyaimage from "../images/backgrounds/trips/1DayTrips/2. Hanumantiya/Hanumantiya-1.jpeg";
import Hanumantiyaimage2 from "../images/backgrounds/trips/1DayTrips/2. Hanumantiya/Hanumantiya-2.jpeg";
import Hanumantiyaimage3 from "../images/backgrounds/trips/1DayTrips/2. Hanumantiya/Hanumantiya-3.jpeg";

// Maheshwar trip images
import Maheshwarimage from "../images/backgrounds/trips/1DayTrips/3. Maheshwar/Maheshwar-1.jpeg";
import Maheshwarimage2 from "../images/backgrounds/trips/1DayTrips/3. Maheshwar/Maheshwar-2.jpeg";
import Maheshwarimage3 from "../images/backgrounds/trips/1DayTrips/3. Maheshwar/Maheshwar-3.jpeg";

// Gulawat Lotus Valley trip images
import GulawatLotusValleyimage from "../images/backgrounds/trips/1DayTrips/4. Gulawat Lotus Valley/Gulawat Lotus Valley-1.jpeg";
import GulawatLotusValleyimage2 from "../images/backgrounds/trips/1DayTrips/4. Gulawat Lotus Valley/Gulawat Lotus Valley-2.jpeg";
import GulawatLotusValleyimage3 from "../images/backgrounds/trips/1DayTrips/4. Gulawat Lotus Valley/Gulawat Lotus Valley-3.jpeg";

// Chidya Bhadak Trek trip images
import ChidyaBhadakTrekimage from "../images/backgrounds/trips/1DayTrips/5. Chidya Bhadak Trek/Chidya Bhadak Trek-1.jpeg";
import ChidyaBhadakTrekimage2 from "../images/backgrounds/trips/1DayTrips/5. Chidya Bhadak Trek/Chidya Bhadak Trek-2.jpeg";
import ChidyaBhadakTrekimage3 from "../images/backgrounds/trips/1DayTrips/5. Chidya Bhadak Trek/Chidya Bhadak Trek-3.jpeg";

// Nakhrali Dhani trip images
import NakhraliDhaniimage from "../images/backgrounds/trips/1DayTrips/6. Nakhrali Dhani/Nakhrali Dhani-1.jpeg";
import NakhraliDhaniimage2 from "../images/backgrounds/trips/1DayTrips/6. Nakhrali Dhani/Nakhrali Dhani-2.jpeg";
import NakhraliDhaniimage3 from "../images/backgrounds/trips/1DayTrips/6. Nakhrali Dhani/Nakhrali Dhani-3.jpeg";

// Choral Resort trip images
import ChoralResortimage from "../images/backgrounds/trips/1DayTrips/7. Choral Resort/Choral Resort-1.jpeg";
import ChoralResortimage2 from "../images/backgrounds/trips/1DayTrips/7. Choral Resort/Choral Resort-2.jpeg";
import ChoralResortimage3 from "../images/backgrounds/trips/1DayTrips/7. Choral Resort/Choral Resort-3.jpeg";

// Malgudi Days trip images
import MalgudiDaysimage from "../images/backgrounds/trips/1DayTrips/8. Malgudi Days/Malgudi Days-1.jpeg";
import MalgudiDaysimage2 from "../images/backgrounds/trips/1DayTrips/8. Malgudi Days/Malgudi Days-2.jpeg";
import MalgudiDaysimage3 from "../images/backgrounds/trips/1DayTrips/8. Malgudi Days/Malgudi Days-3.jpeg";

// Sanchi Stupa trip images
import SanchiStupaimage from "../images/backgrounds/trips/1DayTrips/9. Sanchi Stupa/Sanchi Stupa-1.jpeg";
import SanchiStupaimage2 from "../images/backgrounds/trips/1DayTrips/9. Sanchi Stupa/Sanchi Stupa-2.jpeg";
import SanchiStupaimage3 from "../images/backgrounds/trips/1DayTrips/9. Sanchi Stupa/Sanchi Stupa-3.jpeg";

//                                      WEEKEND TRIPS

// Panchmari trip images
import Panchmariimage from "../images/backgrounds/trips/WeekendTrips/1. Panchmari/Panchmari-1.jpeg";
import Panchmariimage2 from "../images/backgrounds/trips/WeekendTrips/1. Panchmari/Panchmari-2.jpeg";
import Panchmariimage3 from "../images/backgrounds/trips/WeekendTrips/1. Panchmari/Panchmari-3.jpeg";

// Bandhavgarh trip images
import Bandhavgarhimage from "../images/backgrounds/trips/WeekendTrips/2. Bandhavgarh/Bandhavgarh-1.jpeg";
import Bandhavgarhimage2 from "../images/backgrounds/trips/WeekendTrips/2. Bandhavgarh/Bandhavgarh-2.jpeg";
import Bandhavgarhimage3 from "../images/backgrounds/trips/WeekendTrips/2. Bandhavgarh/Bandhavgarh-3.jpeg";

// Kanha National Park trip images
import KanhaNationalParkimage from "../images/backgrounds/trips/WeekendTrips/3. Kanha National Park/Kanha National Park-1.jpeg";
import KanhaNationalParkimage2 from "../images/backgrounds/trips/WeekendTrips/3. Kanha National Park/Kanha National Park-2.jpeg";
import KanhaNationalParkimage3 from "../images/backgrounds/trips/WeekendTrips/3. Kanha National Park/Kanha National Park-3.jpeg";

// Orcha trip images
import Orchaimage from "../images/backgrounds/trips/WeekendTrips/4. Orcha/Orcha-1.jpeg";
import Orchaimage2 from "../images/backgrounds/trips/WeekendTrips/4. Orcha/Orcha-2.jpeg";
import Orchaimage3 from "../images/backgrounds/trips/WeekendTrips/4. Orcha/Orcha-3.jpeg";

// Khajuraho trip images
import Khajurahoimage from "../images/backgrounds/trips/WeekendTrips/5. Khajuraho/Khajuraho-1.jpeg";
import Khajurahoimage2 from "../images/backgrounds/trips/WeekendTrips/5. Khajuraho/Khajuraho-2.jpeg";
import Khajurahoimage3 from "../images/backgrounds/trips/WeekendTrips/5. Khajuraho/Khajuraho-3.jpeg";

// Maheshwar trip images
import Maheshwartripimage from "../images/backgrounds/trips/WeekendTrips/6. Maheshwar/Maheshwar-1.jpeg";
import Maheshwartripimage2 from "../images/backgrounds/trips/WeekendTrips/6. Maheshwar/Maheshwar-2.jpeg";
import Maheshwartripimage3 from "../images/backgrounds/trips/WeekendTrips/6. Maheshwar/Maheshwar-1.jpeg";

// Udaipur & Mount Abu trip images
import UdaipurMountAbuimage from "../images/backgrounds/trips/WeekendTrips/7. Udaipur & Mount Abu/Udaipur & Mount Abu-1.jpeg";
import UdaipurMountAbuimage2 from "../images/backgrounds/trips/WeekendTrips/7. Udaipur & Mount Abu/Udaipur & Mount Abu-2.jpeg";
import UdaipurMountAbuimage3 from "../images/backgrounds/trips/WeekendTrips/7. Udaipur & Mount Abu/Udaipur & Mount Abu-3.jpeg";

// Mount Abu & Chittorgarh trip images
import MountAbuChittorgarhimage from "../images/backgrounds/trips/WeekendTrips/8. Mount Abu & Chittorgarh/Mount Abu & Chittorgarh-1.jpeg";
import MountAbuChittorgarhimage2 from "../images/backgrounds/trips/WeekendTrips/8. Mount Abu & Chittorgarh/Mount Abu & Chittorgarh-2.jpeg";
import MountAbuChittorgarhimage3 from "../images/backgrounds/trips/WeekendTrips/8. Mount Abu & Chittorgarh/Mount Abu & Chittorgarh-3.jpeg";

// Ranthambore trip images
import Ranthamboreimage from "../images/backgrounds/trips/WeekendTrips/9. Ranthambore/Ranthambore-1.jpeg";
import Ranthamboreimage2 from "../images/backgrounds/trips/WeekendTrips/9. Ranthambore/Ranthambore-2.jpeg";
import Ranthamboreimage3 from "../images/backgrounds/trips/WeekendTrips/9. Ranthambore/Ranthambore-3.jpeg";

const thingsToCarry = [
  "Keep your mobile phone with you for communication, navigation, and capturing memories.",
  "Wear or carry a pair of comfortable and sturdy shoes suitable for the activities you'll be doing.",
  "Carry a sturdy backpack or bag to keep all your belongings organized and easily accessible.",
  "If you have any prescribed medications, ensure you carry them along with any necessary medical supplies.",
  "Carry your identity card for emergencies.",
];

const tripDetailstab = [
  "Transport Included",
  "Meals Included",
  "Stay Included",
  "Sightseeing",
];

export const tripDetails = {
  // one day

  mandavgarh: {
    category: "oneday",
    link: "/trip/mandavgarh",
    location: "Mandavgarh",
    tagline: "One day of pleasure and relaxation: Mandavgarh",
    keywords: ["beauty", "hills", "water", "fountain", "oneday"],
    tabData: [
      {
        tabname: "Trip Details",
        heading:
          "Architectural marvels and panoramic views of the surrounding landscape",
        points: tripDetailstab,
        image: Mandavgarhimage,
      },
      {
        tabname: "Must See Excursions",
        points: [
          "Mandav, also known as Mandu, is a historic city located in the state of Madhya Pradesh, India. It offers a captivating blend of architectural marvels, natural beauty, and rich history.",
          "Explore the grand structures of Mandav, such as the Jahaz Mahal, a stunning palace resembling a ship floating on water.",
          "Enjoy the serene beauty of Mandav's lush gardens and lakes, such as the picturesque Rupmati Pavilion and the tranquil Rewa Kund. ",
          "Immerse yourself in the tales of love and valor as you visit Mandav's historical sites, experience the local culture, and savor the delicious local cuisine. ",
        ],
      },
      {
        tabname: "Quick Information",
        image: Mandavgarhimage2,
        quickInfo: ["1 Day", "Mandav"],
        heading:
          "Plan Your Perfect Getaway: Destination and Duration of Your Trip",
      },
      {
        tabname: "Things To Carry",
        points: thingsToCarry,
        image: Mandavgarhimage3,
      },
      {
        tabname: "Book Now",
        points: thingsToCarry,
      },
    ],
  },
  hanumantiya: {
    category: "oneday",
    link: "/trip/hanumantiya",
    location: "Hanumantiya",
    tagline: "Where Nature Embraces You in its Tranquil Beauty: Hanumantiya",
    keywords: ["beauty", "water", "fountain", "oneday"],
    tabData: [
      {
        tabname: "Trip Details",
        heading:
          "Architectural marvels and panoramic views of the surrounding landscape",
        points: tripDetailstab,
        image: Hanumantiyaimage,
      },
      {
        tabname: "Must See Excursions",
        points: [
          "Hanumantiya, located in Madhya Pradesh, offers a delightful getaway with its scenic beauty and thrilling adventure options.",
          "Enjoy a peaceful boat ride on the backwaters of the majestic Indira Sagar Dam, surrounded by serene landscapes.",
          "Explore Hanumantiya Island, where you can indulge in exciting water sports and experience the adrenaline rush. With its picturesque views, thrilling activities, and tranquil ambiance. ",
          "Hanumantiya is the perfect destination for nature lovers and adventure enthusiasts looking to enjoy a memorable trip.",
        ],
      },
      {
        tabname: "Quick Information",
        image: Hanumantiyaimage2,
        quickInfo: ["1 Day", "Hanumantiya"],
        heading:
          "Plan Your Perfect Getaway: Destination and Duration of Your Trip",
      },
      {
        tabname: "Things To Carry",
        points: thingsToCarry,
        image: Hanumantiyaimage3,
      },
      {
        tabname: "Book Now",
        points: thingsToCarry,
      },
    ],
  },
  maheshwar: {
    category: "oneday",
    location: "Maheshwar",
    link: "/trip/maheshwar",
    tagline: "Discover the timeless charm of Maheshwar",
    keywords: ["beauty", "water", "fountain", "oneday"],
    tabData: [
      {
        tabname: "Trip Details",
        heading:
          "Architectural marvels and panoramic views of the surrounding landscape",
        points: tripDetailstab,
        image: Maheshwarimage,
      },
      {
        tabname: "Must See Excursions",
        points: [
          "Maheshwar, located on the banks of the majestic Narmada River, offers a delightful blend of spirituality, history, and natural beauty.",
          "Indulge in the rich handloom tradition of Maheshwar by visiting the famous Maheshwari saree weavers, where you can witness the intricate craftsmanship firsthand.",
          "Whether you seek spiritual solace, historical exploration, or simply a peaceful getaway, Maheshwar offers a truly enchanting experience",
          "Boat ride on the Narmada River during sunset and witness the mesmerizing views of the town and its ghats bathed in golden hues.",
        ],
      },
      {
        tabname: "Quick Information",
        image: Maheshwarimage2,
        quickInfo: ["1 Day", "Madav"],
        heading:
          "Plan Your Perfect Getaway: Destination and Duration of Your Trip",
      },
      {
        tabname: "Things To Carry",
        points: thingsToCarry,
        image: Maheshwarimage3,
      },
      {
        tabname: "Book Now",
        points: thingsToCarry,
      },
    ],
  },
  gulawatLotusValley: {
    category: "oneday",
    link: "/trip/gulawatLotusValley",
    location: "Gulawat Lotus Valley",
    tagline: "Gulawat Lotus Valley – Where Beauty Blossoms!",
    keywords: ["beauty", "water", "fountain", "oneday"],
    tabData: [
      {
        tabname: "Trip Details",
        heading:
          "Architectural marvels and panoramic views of the surrounding landscape",
        points: tripDetailstab,
        image: GulawatLotusValleyimage,
      },
      {
        tabname: "Must See Excursions",
        points: [
          "The prime attraction of Gulawat Lotus Valley Indore is a lake that is fully-fledged with thousands of beautiful lotuses on its surface, which makes it Asia’s Largest Lotus Valley.",
          "Other things of attractions are the bamboo tree forest, bridge over the lake, activities like horse riding, swinging, boating, photography, and a temple dedicated to Lord Hanuman located nearby.",
          "People head towards this hub of lotuses with their family, and friends to hang out, and even solo to witness this awesomeness.",
          "The lake is also home to various alluring birds during their migratory transit, which adds to its brilliance.",
        ],
      },
      {
        tabname: "Quick Information",
        image: GulawatLotusValleyimage2,
        quickInfo: ["1 Day", "Gulawat lotus valley"],
        heading:
          "Plan Your Perfect Getaway: Destination and Duration of Your Trip",
      },
      {
        tabname: "Things To Carry",
        points: thingsToCarry,
        image: GulawatLotusValleyimage3,
      },
      {
        tabname: "Book Now",
        points: thingsToCarry,
      },
    ],
  },
  chidyaBhadakTrek: {
    category: "oneday",
    link: "/trip/chidyaBhadakTrek",
    location: "Chidya Bhadak Trek",
    tagline: "Embark on an Avian Adventure",
    keywords: ["beauty", "hills", "water", "fountain"],
    tabData: [
      {
        tabname: "Trip Details",
        heading:
          "Architectural marvels and panoramic views of the surrounding landscape",
        points: tripDetailstab,
        image: ChidyaBhadakTrekimage,
      },
      {
        tabname: "Must See Excursions",
        points: [
          "Explore the mesmerizing trails and catch glimpses of migratory birds while unfolding the trek.",
          "Experience the comfort of round trip transfers and the supervision of an experienced guide included in this activity.",
          "Enveloped in lush greenery, and rocky cliff terrain with mesmerizing waterfall view.",
          "Take a dip in the crystal clear, cool water at Chidiya Bhadak and enjoy the views of scenic vistas in the backdrop. ",
        ],
      },
      {
        tabname: "Quick Information",
        image: ChidyaBhadakTrekimage2,
        quickInfo: ["1 Day", "Chidya Bhadak Trek"],
        heading:
          "Plan Your Perfect Getaway: Destination and Duration of Your Trip",
      },
      {
        tabname: "Things To Carry",
        points: thingsToCarry,
        image: ChidyaBhadakTrekimage3,
      },
      {
        tabname: "Book Now",
        points: thingsToCarry,
      },
    ],
  },
  nakhralidhani: {
    category: "oneday",
    link: "/trip/nakhralidhani",
    location: "Nakhrali Dhani",
    tagline: "Escape to the Rich Cultural Heritage",
    keywords: ["beauty", "water", "fountain", "oneday"],
    tabData: [
      {
        tabname: "Trip Details",
        heading:
          "Architectural marvels and panoramic views of the surrounding landscape",
        points: tripDetailstab,
        image: NakhraliDhaniimage,
      },
      {
        tabname: "Must See Excursions",
        points: [
          "Nakhrali Dhani is a vibrant Rajasthani-themed village resort located near Indore, Madhya Pradesh. It offers a delightful experience for visitors seeking to immerse themselves in the rich culture and traditions of Rajasthan.",
          "Indulge in traditional folk dance performances, mesmerizing puppet shows, and live music that showcase the vibrant heritage of Rajasthan.",
          "Savor the flavors of Rajasthan with a delectable array of traditional Rajasthani cuisine at the resort's restaurant. From spicy curries to mouthwatering sweets, the food here is a true delight for your taste buds.",
          "Nakhrali Dhani offers a perfect blend of entertainment, cultural immersion, and gastronomic delights, making it an ideal destination for a memorable trip filled with joy and celebration.",
        ],
      },
      {
        tabname: "Quick Information",
        image: NakhraliDhaniimage2,
        quickInfo: ["1 Day", "Nakhrali Dhani"],
        heading:
          "Plan Your Perfect Getaway: Destination and Duration of Your Trip",
      },
      {
        tabname: "Things To Carry",
        points: thingsToCarry,
        image: NakhraliDhaniimage3,
      },
      {
        tabname: "Book Now",
        points: thingsToCarry,
      },
    ],
  },
  choralResort: {
    category: "oneday",
    link: "/trip/choralResort",
    location: "Choral Resort",
    tagline: "Unwind, Explore, and Reconnect at Choral Resort",
    keywords: ["beauty", "water", "fountain", "oneday"],
    tabData: [
      {
        tabname: "Trip Details",
        heading:
          "Architectural marvels and panoramic views of the surrounding landscape",
        points: tripDetailstab,
        image: ChoralResortimage,
      },
      {
        tabname: "Must See Excursions",
        points: [
          "Choral Resort is a blissful retreat nestled amidst nature near Indore, Madhya Pradesh. Surrounded by lush forests, cascading waterfalls, and serene landscapes, it offers a perfect getaway to relax and rejuvenate.",
          "The resort provides a range of amenities and activities, including comfortable accommodations, swimming pools, adventure sports like trekking and rappelling, and a tranquil spa for ultimate relaxation.",
          "Whether you want to indulge in outdoor adventures, unwind in the lap of nature, or simply enjoy the scenic beauty, Choral Resort promises a memorable trip filled with tranquility and excitement.",
        ],
      },
      {
        tabname: "Quick Information",
        image: ChoralResortimage2,
        quickInfo: ["1 Day", "ChoralResort"],
        heading:
          "Plan Your Perfect Getaway: Destination and Duration of Your Trip",
      },
      {
        tabname: "Things To Carry",
        points: thingsToCarry,
        image: ChoralResortimage3,
      },
      {
        tabname: "Book Now",
        points: thingsToCarry,
      },
    ],
  },
  malgudiDays: {
    category: "oneday",
    link: "/trip/malgudiDays",
    location: "Malgudi Days",
    tagline: "Step into the enchanting world of Malgudi at Ujjain",
    keywords: ["beauty", "hills", "fountain", "oneday"],
    tabData: [
      {
        tabname: "Trip Details",
        heading:
          "Architectural marvels and panoramic views of the surrounding landscape",
        points: tripDetailstab,
        image: MalgudiDaysimage,
      },
      {
        tabname: "Must See Excursions",
        points: [
          "Malgudi Days Ujjain is a delightful themed restaurant that transports you to the fictional town of Malgudi, inspired by the works of renowned Indian author R.K. Narayan.",
          "Step into a charming world of nostalgia as you indulge in a culinary journey filled with traditional flavors and cultural experiences.",
          "The restaurant offers a unique dining experience with its rustic ambiance, reminiscent of a bygone era, and serves delectable dishes that showcase the rich diversity of Indian cuisine.",
          "Malgudi Days Ujjain is the perfect place to immerse yourself in the enchanting world of Malgudi and savor the flavors of India.",
        ],
      },
      {
        tabname: "Quick Information",
        image: MalgudiDaysimage2,
        quickInfo: ["1 Day", "Malgudi Days"],
        heading:
          "Plan Your Perfect Getaway: Destination and Duration of Your Trip",
      },
      {
        tabname: "Things To Carry",
        points: thingsToCarry,
        image: MalgudiDaysimage3,
      },
      {
        tabname: "Book Now",
        points: thingsToCarry,
      },
    ],
  },
  sachiStupa: {
    category: "oneday",
    link: "/trip/sachiStupa",
    location: "Sachi Stupa",
    tagline: " journey to inner peace!",
    keywords: ["beauty", "water", "fountain", "oneday"],
    tabData: [
      {
        tabname: "Trip Details",
        heading:
          "Architectural marvels and panoramic views of the surrounding landscape",
        points: tripDetailstab,
        image: SanchiStupaimage,
      },
      {
        tabname: "Must See Excursions",
        points: [
          "It is one of the oldest and most well-preserved stone structures in the country.",
          "The intricate carvings and sculptures on the stupa depict stories from the life of Buddha, making it a significant pilgrimage site for Buddhists and a fascinating cultural site for history enthusiasts.",
          "Exploring the site, visitors can walk around the stupa, marvel at the ornate gateways called toranas, and admire the beautiful stone workmanship.",
          "A visit to the Sanchi Stupa is not only a journey into ancient history but also an opportunity to connect with the profound teachings of Buddhism and experience a sense of tranquility amidst the remarkable architectural beauty.",
        ],
      },
      {
        tabname: "Quick Information",
        image: SanchiStupaimage2,
        quickInfo: ["1 Day", "SachiStupa"],
        heading:
          "Plan Your Perfect Getaway: Destination and Duration of Your Trip",
      },
      {
        tabname: "Things To Carry",
        points: thingsToCarry,
        image: SanchiStupaimage3,
      },
      {
        tabname: "Book Now",
        points: thingsToCarry,
      },
    ],
  },

  // weekend

  pachmarhi: {
    category: "weekend",
    link: "/trip/pachmarhi",
    location: "Pachmarhi",
    tagline: "“Panchmarvelous: Discover Nature's Paradise in Pachmarhi!",
    keywords: ["beauty", "hills", "fountain", "weekend"],
    tabData: [
      {
        tabname: "Trip Details",
        heading:
          "Architectural marvels and panoramic views of the surrounding landscape",
        points: tripDetailstab,
        image: Panchmariimage,
      },
      {
        tabname: "Must See Excursions",
        points: [
          "A scenic hill station nestled in the heart of Madhya Pradesh. Immerse yourself in the natural beauty.",
          "Explore the breathtaking attractions of Pachmarhi, including the majestic Bee Falls, and the inspiring Dhoopgarh Sunset, where you can witness a mesmerizing sky.",
          "On day two, venture into the stunning Pandav Caves and marvel at the rock-cut architecture and ancient legends they hold. Visit the enchanting Jata Shankar Cave. ",
          "Pachmarhi promises an unforgettable two-day trip filled with natural wonders and cherished memories. ",
        ],
      },
      {
        tabname: "Quick Information",
        image: Panchmariimage2,
        quickInfo: ["Weekend trip Day", "Pachmarhi"],
        heading:
          "Plan Your Perfect Getaway: Destination and Duration of Your Trip",
      },
      {
        tabname: "Things To Carry",
        points: thingsToCarry,
        image: Panchmariimage3,
      },
      {
        tabname: "Book Now",
        points: thingsToCarry,
      },
    ],
  },
  bandhavgarh: {
    category: "weekend",
    link: "/trip/bandhavgarh",
    location: "Bandhavgarh",
    tagline: "Explore the Majestic Wilderness of Bandhavgarh",
    keywords: ["beauty", "hills", "fountain", "weekend"],
    tabData: [
      {
        tabname: "Trip Details",
        heading:
          "Architectural marvels and panoramic views of the surrounding landscape",
        points: tripDetailstab,
        image: Bandhavgarhimage,
      },
      {
        tabname: "Must See Excursions",
        points: [
          "Immerse yourself in the untamed beauty and rich biodiversity of this renowned national park.",
          "You can spot the majestic Royal Bengal Tigers, elusive leopards, and a myriad of other wildlife species in their natural habitat.",
          "Explore the park's hidden treasures, including the ancient Bandhavgarh Fort, perched atop a hill, offering panoramic views of the surrounding wilderness.",
          "An exhilarating two-day trip where you can witness the wonders of the wild and create memories to last a lifetime.",
        ],
      },
      {
        tabname: "Quick Information",
        image: Bandhavgarhimage2,
        quickInfo: ["Weekend trip Day", "Bandhavgarh"],
        heading:
          "Plan Your Perfect Getaway: Destination and Duration of Your Trip",
      },
      {
        tabname: "Things To Carry",
        points: thingsToCarry,
        image: Bandhavgarhimage3,
      },
      {
        tabname: "Book Now",
        points: thingsToCarry,
      },
    ],
  },
  kanhaNationalPark: {
    category: "weekend",
    link: "/trip/kanhaNationalPark",
    location: "Kanha National Park",
    tagline: "Discover Wildlife Wonderland at Kanha National Park",
    keywords: ["beauty", "hills", "fountain", "weekend"],
    tabData: [
      {
        tabname: "Trip Details",
        heading:
          "Architectural marvels and panoramic views of the surrounding landscape",
        points: tripDetailstab,
        image: KanhaNationalParkimage,
      },
      {
        tabname: "Must See Excursions",
        points: [
          "Immerse yourself in the lush green forests and pristine landscapes that provide a diversity of flora and fauna.",
          "Day one takes you on thrilling jungle safaris, where expert guides lead you through the dense wilderness.",
          "Capture the essence of Kanha as you soak in the tranquil beauty and immerse yourself in the rhythms of the jungle. ",
          "Kanha National Park promises an unforgettable two-day trip for wildlife enthusiasts and nature lovers alike.",
        ],
      },
      {
        tabname: "Quick Information",
        image: KanhaNationalParkimage2,
        quickInfo: ["Weekend trip Day", "Kanha National Park"],
        heading:
          "Plan Your Perfect Getaway: Destination and Duration of Your Trip",
      },
      {
        tabname: "Things To Carry",
        points: thingsToCarry,
        image: KanhaNationalParkimage3,
      },
      {
        tabname: "Book Now",
        points: thingsToCarry,
      },
    ],
  },
  orcha: {
    category: "weekend",
    link: "/trip/orcha",
    location: "Orcha",
    tagline: "Step into the Timeless Grandeur",
    keywords: ["beauty", "hills", "fountain", "weekend"],
    tabData: [
      {
        tabname: "Trip Details",
        heading:
          "Architectural marvels and panoramic views of the surrounding landscape",
        points: tripDetailstab,
        image: Orchaimage,
      },
      {
        tabname: "Must See Excursions",
        points: [
          "Uncover the secrets of a bygone era in the enchanting town of Orchha, where history comes alive in vibrant hues.",
          "With their intricate carvings and captivating stories of royalty. Wander through the serene cenotaphs along the Betwa River, a testament to the valor of Orchha's past rulers.",
          "Stroll across the picturesque Orchha Wildlife Sanctuary, where nature's beauty intertwines with historical remnants, creating a unique blend of tranquility and antiquity.",
          "Orchha promises an unforgettable two-day trip where you can relish the beauty of the past while immersing yourself in the charm of the present.",
        ],
      },
      {
        tabname: "Quick Information",
        image: Orchaimage2,
        quickInfo: ["Weekend trip Day", "Orcha"],
        heading:
          "Plan Your Perfect Getaway: Destination and Duration of Your Trip",
      },
      {
        tabname: "Things To Carry",
        points: thingsToCarry,
        image: Orchaimage3,
      },
      {
        tabname: "Book Now",
        points: thingsToCarry,
      },
    ],
  },
  khajuraho: {
    category: "weekend",
    link: "/trip/khajuraho",
    location: "Khajuraho",
    tagline: "“Divine Dance of Sensuality and Spirituality",
    keywords: ["beauty", "hills", "fountain", "weekend"],
    tabData: [
      {
        tabname: "Trip Details",
        heading:
          "Architectural marvels and panoramic views of the surrounding landscape",
        points: tripDetailstab,
        image: Khajurahoimage,
      },
      {
        tabname: "Must See Excursions",
        points: [
          "Discover the timeless allure of Khajuraho, where ancient temples depict an exquisite fusion of sensuality and spirituality.",
          "Experience the artistic brilliance that transcends time and transports you to a world of divine beauty.",
          "Delve deeper into Khajuraho's rich heritage by visiting the Eastern and Southern Group of Temples and having fun with your loved ones.",
          "With its captivating blend of art, history, and spirituality, Khajuraho offers a unique journey into the depths of human expression.",
        ],
      },
      {
        tabname: "Quick Information",
        image: Khajurahoimage2,
        quickInfo: ["Weekend trip Day", "Khajuraho"],
        heading:
          "Plan Your Perfect Getaway: Destination and Duration of Your Trip",
      },
      {
        tabname: "Things To Carry",
        points: thingsToCarry,
        image: Khajurahoimage3,
      },
      {
        tabname: "Book Now",
        points: thingsToCarry,
      },
    ],
  },
  MaheshwarTrip: {
    category: "weekend",
    link: "/trip/MaheshwarTrip",
    location: "Maheshwar",
    tagline: "“Maheshwar: Where Tradition Meets Serenity",
    keywords: ["beauty", "hills", "fountain", "weekend"],
    tabData: [
      {
        tabname: "Trip Details",
        heading:
          "Architectural marvels and panoramic views of the surrounding landscape",
        points: tripDetailstab,
        image: Maheshwartripimage,
      },
      {
        tabname: "Must See Excursions",
        points: [
          "Immerse yourself in the rich heritage, vibrant culture, and scenic beauty that this riverside destination offers.",
          "Explore its majestic halls, ancient temples, and intricate carvings, transporting you back in time.",
          "Embark on a thrilling boat ride on the Narmada River, as you soak in the panoramic views of the surrounding landscapes.",
          "Maheshwar is the perfect destination to create lifelong memories with your friends. Immerse yourselves in the local culture, and savor traditional delicacies.",
        ],
      },
      {
        tabname: "Quick Information",
        image: Maheshwartripimage2,
        quickInfo: ["Weekend trip Day", "Maheshwar"],
        heading:
          "Plan Your Perfect Getaway: Destination and Duration of Your Trip",
      },
      {
        tabname: "Things To Carry",
        points: thingsToCarry,
        image: Maheshwartripimage3,
      },
      {
        tabname: "Book Now",
        points: thingsToCarry,
      },
    ],
  },
  udaipurAndMountAbu: {
    category: "weekend",
    link: "/trip/udaipurAndMountAbu",
    location: "Udaipur & Mount Abu",
    tagline: "“A Tale of Lakes and Hills: Where Nature Meets Culture!",
    keywords: ["beauty", "hills", "fountain", "weekend"],
    tabData: [
      {
        tabname: "Trip Details",
        heading:
          "Architectural marvels and panoramic views of the surrounding landscape",
        points: tripDetailstab,
        image: UdaipurMountAbuimage,
      },
      {
        tabname: "Must See Excursions",
        points: [
          "Udaipur: Nestled amidst the Aravalli Hills, Udaipur is a city that exudes a timeless charm. Known as the City of Lakes and the Venice of the East, it captivates visitors with its enchanting palaces, serene lakes, and intricate architecture.",
          "From the opulent City Palace to the tranquil Lake Pichola, Udaipur offers a perfect blend of regal grandeur and natural beauty.",
          "Mount Abu: Perched atop the Aravalli Range, Mount Abu is a refreshing hill station that provides respite from the scorching heat of Rajasthan. Famous for its cool climate and breathtaking landscapes, it is a haven for nature lovers and adventure seekers.",
          "Explore the stunning Dilwara Jain Temples, take a boat ride on Nakki Lake, or hike to the picturesque viewpoints for panoramic vistas. Mount Abu offers a serene retreat amidst lush greenery and a tranquil atmosphere.",
        ],
      },
      {
        tabname: "Quick Information",
        image: UdaipurMountAbuimage2,
        quickInfo: ["Weekend trip Day", "Udaipur & Mount Abu"],
        heading:
          "Plan Your Perfect Getaway: Destination and Duration of Your Trip",
      },
      {
        tabname: "Things To Carry",
        points: thingsToCarry,
        image: UdaipurMountAbuimage3,
      },
      {
        tabname: "Book Now",
        points: thingsToCarry,
      },
    ],
  },
  mountAbuAndChittorgarh: {
    category: "weekend",
    link: "/trip/mountAbuAndChittorgarh",
    location: "Mount Abu & Chittorgarh",
    tagline:
      "“From serene heights to historic marvels, Explore the magic of Mount Abu & Chittorgarh!",
    keywords: ["beauty", "hills", "fountain", "weekend"],
    tabData: [
      {
        tabname: "Trip Details",
        heading:
          "Architectural marvels and panoramic views of the surrounding landscape",
        points: tripDetailstab,
        image: MountAbuChittorgarhimage,
      },
      {
        tabname: "Must See Excursions",
        points: [
          "Escape to the serene beauty of Mount Abu, a charming hill station nestled in the Aravalli Range, offering a refreshing break from city life.",
          "Explore the historic grandeur of Chittorgarh, home to the majestic Chittorgarh Fort, a UNESCO World Heritage Site known for its fascinating architecture and legendary tales of valor.",
          "Discover the architectural marvels of Mount Abu, including the exquisite Dilwara Jain Temples and the tranquil Nakki Lake, where you can enjoy boating amidst picturesque surroundings.",
          "Immerse yourself in the rich history of Chittorgarh as you wander through the ancient fort walls, visit the iconic Vijay Stambh and Kirti Stambh, and delve into the captivating stories of Rani Padmini and Rana Kumbha.",
          "Experience the best of both worlds as you indulge in the natural beauty of Mount Abu's scenic viewpoints and lakes, and delve into the regal past of Chittorgarh, creating unforgettable memories that blend tranquility and heritage.",
        ],
      },
      {
        tabname: "Quick Information",
        image: MountAbuChittorgarhimage2,
        quickInfo: ["Weekend trip Day", "Mount Abu & Chittorgarh"],
        heading:
          "Plan Your Perfect Getaway: Destination and Duration of Your Trip",
      },
      {
        tabname: "Things To Carry",
        points: thingsToCarry,
        image: MountAbuChittorgarhimage3,
      },
      {
        tabname: "Book Now",
        points: thingsToCarry,
      },
    ],
  },
  ranthambore: {
    category: "weekend",
    link: "/trip/ranthambore",
    location: "Ranthambore",
    tagline: "“A Tale of Lakes and Hills: Where Nature Meets Culture!",
    keywords: ["beauty", "hills", "fountain", "weekend"],
    tabData: [
      {
        tabname: "Trip Details",
        heading:
          "Architectural marvels and panoramic views of the surrounding landscape",
        points: tripDetailstab,
        image: Ranthamboreimage,
      },
      {
        tabname: "Must See Excursions",
        points: [
          "Embark on a thrilling wildlife expedition in Ranthambore National Park, Rajasthan's crown jewel of biodiversity.",
          "Witness the raw beauty of nature as you encounter the majestic Bengal tigers, leopards, and a rich variety of wildlife in their natural habitat.",
          "Immerse yourself in the stunning landscapes of Ranthambore, from dense forests to serene lakes, creating a picturesque backdrop for your adventure.",
          "Experience the adrenaline rush of safari rides, guided by experts who will unveil the secrets of the park and provide unforgettable encounters with nature's untamed treasures.",
        ],
      },
      {
        tabname: "Quick Information",
        image: Ranthamboreimage2,
        quickInfo: ["Weekend trip Day", "Ranthambore"],
        heading:
          "Plan Your Perfect Getaway: Destination and Duration of Your Trip",
      },
      {
        tabname: "Things To Carry",
        points: thingsToCarry,
        image: Ranthamboreimage3,
      },
      {
        tabname: "Book Now",
        points: thingsToCarry,
      },
    ],
  },
};

export const tripLocations = Object.keys(tripDetails);
