import { combineReducers } from "redux";
import counterReducer from "./reducers/counter";
import sidebarReducer from "./reducers/sidebar";
import popUpReducer from "./reducers/popup";
import accountPopUpReducer from "./reducers/accountPopup";
import darkmodeReducer from "./reducers/darkmode";
import contactdataReducer from "./reducers/contactData";
import userInfoReducer from "./reducers/userInfo";

const rootReducer = combineReducers({
  counter: counterReducer,
  sidebar: sidebarReducer,
  popup: popUpReducer,
  accountpopup: accountPopUpReducer,
  darkmode: darkmodeReducer,
  contactdata: contactdataReducer,
  userInfo: userInfoReducer,
});

export default rootReducer;
