import React, { useState, useEffect } from "react";
import logoimgdark from "../images/logos/gftlogodark.png";
import logoimglight from "../images/logos/gftlogolight.png";
import { useSelector } from "react-redux";

const AnimatedCursor = ({ targetClassname }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isOverClassname, setIsOverClassname] = useState(false);

  useEffect(() => {
    const handleMouseMove = (event) => {
      setPosition({ x: event.clientX - 15, y: event.clientY - 15 });
    };

    const handleMouseEnter = () => {
      setIsOverClassname(true);
    };

    const handleMouseLeave = () => {
      setIsOverClassname(false);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseenter", handleMouseEnter);
    document.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseenter", handleMouseEnter);
      document.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  const darkmodestate = useSelector((state) => state.darkmode);

  const cursorStyle = {
    position: "fixed",
    top: position.y,
    left: position.x,
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    backgroundColor: "var(--secondary)",
    pointerEvents: "none",
    zIndex: 9999,
  };

  useEffect(() => {
    const handleMouseOverTarget = (event) => {
      const isOverTarget = event.target.classList.contains(targetClassname);
      setIsOverClassname(isOverTarget);
    };

    document.addEventListener("mouseover", handleMouseOverTarget);

    return () => {
      document.removeEventListener("mouseover", handleMouseOverTarget);
    };
  }, [targetClassname]);

  return (
    <div
      className={`animated-cursor drop-shadow-lg opacity-50 p-1 hidden ${
        isOverClassname ? "lg:hidden" : "lg:block"
      }`}
      style={cursorStyle}
    >
      <img
        loading="lazy"
        src={darkmodestate === "dark" ? logoimgdark : logoimglight}
        className="h-full w-full object-contain object-center"
      />
    </div>
  );
};

export default AnimatedCursor;
