import { createSlice } from "@reduxjs/toolkit";

const AccountPopUpSlice = createSlice({
  name: "accountpopup",
  initialState: false,
  reducers: {
    setAccountPopUpVisibility: (state, actions) => actions.payload,
  },
});

export const { setAccountPopUpVisibility } = AccountPopUpSlice.actions;

export default AccountPopUpSlice.reducer;
