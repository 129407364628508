import React from "react";
import img from "../images/backgrounds/ceo.jpeg";

const FromCeo = () => {
  return (
    <div className="w-full bg-secondary">
      <div className="w-4/5 xl:w-2/5 m-auto pt-20 dark:text-black text-white">
        <h1 className="font-cursiveFont text-6xl text-center mb-10">
          From Our <span className="font-bold">CEO</span>
        </h1>
        <div className="h-full w-full pb-20">
          <div className="xl:float-right w-25 xl:m-4 mt-2 h-[50vh] mb-4">
            <img
              src={img}
              alt="CEO's Image"
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div className="text-justify text-2xl space-y-4">
            <p>
              It all started with a spark of wanderlust. Armed with a backpack
              full of dreams and a mind brimming with crazy ideas, I took a leap
              of faith and founded this unconventional travel company,{" "}
              <span className="font-medium">Go Foot Travels</span>. Little did I
              know that within just one month, we would embark on not one, but
              two incredibly successful trips that left us hungry for more!
            </p>
            <p>
              Our journey began with a beautiful dance with destiny. We hopped
              on a rollercoaster of destinations, exploring the hidden gems of
              the world one enthusiastic traveler at a time.
            </p>
            <p>
              But you know what? It wasn't just the destinations that made our
              journey extraordinary; it was the unbreakable bond we've formed as
              a wacky travel tribe. We've laughed until we’ve cried, danced like
              there's no tomorrow.
            </p>
            <p>
              <span className="font-medium">P.S. Be warned:</span> Side effects
              may include uncontrollable laughter, an addiction to spontaneous
              dance parties, and an ever-growing case of wanderlust. You have
              been warned!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FromCeo;
