import React from "react";
import "./loader.css";

const Loader = () => {
  return (
    <div className="flex justify-center items-center h-full w-full absolute top-0 left-0 bg-[#ffffffc9] z-[999]">
      <span className="loader"></span>
    </div>
  );
};

export default Loader;
