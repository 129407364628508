import { createSlice } from "@reduxjs/toolkit";

const darkmodeSlice = createSlice({
  name: "darkmode",
  initialState: "dark",
  reducers: {
    toggle: (state) => (state === "dark" ? "light" : "dark"),
  },
});

export const { toggle } = darkmodeSlice.actions;

export default darkmodeSlice.reducer;
