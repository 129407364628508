import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import LoaderFs from "./loader/LoaderFs";
import { setPopUpVisibility } from "../redux/reducers/popup";
import { useDispatch, useSelector } from "react-redux";
import { handleContactDataChange } from "../redux/reducers/contactData";
import darkModeImage from "../images/logos/gftlogolowopacity.png";
import { useParams, useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";

const toastOptions = {
  position: "top-center",
  autoClose: 2000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: true,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "dark",
};

const PopUP = () => {
  const dispatch = useDispatch();
  const popupState = useSelector((state) => state.popup);
  const [isLoading, setIsLoading] = useState(false);
  const [pos, setPos] = useState(false);
  const dataState = useSelector((state) => state.contactdata);
  // const location = useParams();
  const location = useLocation();
  const [locationName, setLocationName] = useState("");

  useEffect(() => {
    if (location.pathname.includes("/trip/")) {
      setLocationName(
        `https://api.whatsapp.com/send/?phone=9118811192&text=Hello%21%20Could%20you%20please%20assist%20me%20with%20a%20trip%20to%20${
          location.pathname.split("/")[2]
        }%3F`
      );
    } else {
      setLocationName(
        `https://api.whatsapp.com/send/?phone=9118811192&text=Hello%21%20Could%20you%20please%20assist%20me%20with%20a%20trip%3F`
      );
    }
  }, [popupState]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setPopUpVisibility(true));
      setTimeout(() => {
        setPos(true);
      }, 100);
    }, 400);
  }, []);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    dispatch(
      handleContactDataChange({
        name: name,
        value: value,
      })
    );
  };

  const handleSubmit = async () => {
    const response = await axios.post(
      "https://gftapi.onrender.com/email/send",
      dataState,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    setIsLoading(false);
    if (response.data.emailStatus) {
      toast.success("we will get back to you soon!", toastOptions);
      dispatch(setPopUpVisibility(false));
    } else {
      toast.error("there was an error please try again!", toastOptions);
    }
  };

  const handleValidation = (e) => {
    e.preventDefault();
    if (dataState.name === "") {
      toast.error("Please enter your Name", toastOptions);
      return;
    }
    if (dataState.email === "") {
      toast.error("Please enter your Email", toastOptions);
      return;
    }
    if (dataState.userLocation === "") {
      toast.error("Please enter your location", toastOptions);
      return;
    }
    if (dataState.contact === "") {
      toast.error("Please enter your phone Number", toastOptions);
      return;
    }
    if (dataState.message === "") {
      toast.error("Please enter a message", toastOptions);
      return;
    }
    setIsLoading(true);
    handleSubmit();
  };

  return (
    <div
      className={`h-screen w-full bg-[#4d4b4ba4] fixed top-0 left-0 z-[9999] justify-center items-center ${
        popupState ? "flex" : "hidden"
      }`}
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          dispatch(setPopUpVisibility(false));
        }
      }}
    >
      <div
        className="h-4/5 w-11/12 lg:w-3/5 lg:h-3/5 flex justify-center items-center bg-secondary rounded-lg shadow-md relative duration-200"
        style={{ transform: `scale(${pos ? "100%" : "0%"})` }}
      >
        <i
          className="fa-sharp fa-solid fa-circle-xmark absolute top-2 right-2 text-xl hover:animate-spin hover:text-red-600 duration-100 pointer-events-auto cursor-pointer rounded-full"
          onClick={() => {
            dispatch(setPopUpVisibility(false));
          }}
        ></i>
        {isLoading && <LoaderFs />}
        <form
          className="h-4/5 w-4/5 flex flex-col justify-evenly items-start bg-secondary bg-center bg-contain bg-no-repeat relative"
          onSubmit={handleValidation}
          style={{ backgroundImage: `url(${darkModeImage})` }}
        >
          <h2 className="capitalize text-2xl text-primary font-bold drop-shadow-md">
            get in touch
          </h2>
          <div className="w-full flex justify-between items-center">
            <div className="group w-2/5">
              <h3 className="uppercase text-xs tracking-widest group-focus-within:text-white duration-200 text-primary">
                Name *
              </h3>
              <input
                autoComplete="off"
                type="text"
                name="name"
                placeholder="name"
                value={dataState.name}
                onChange={handleChange}
                className="border-b group-focus-within:border-white border-b-gray-400  py-2 placeholder:capitalize bg-transparent text-primary focus:outline-none w-full"
              />
            </div>
            <div className="group w-2/5">
              <h3 className="uppercase text-xs tracking-widest group-focus-within:text-white duration-200 text-primary">
                email *
              </h3>
              <input
                autoComplete="off"
                type="email"
                name="email"
                placeholder="enter your email"
                value={dataState.email}
                onChange={handleChange}
                className="border-b group-focus-within:border-white border-b-gray-400  py-2 placeholder:capitalize bg-transparent text-primary focus:outline-none w-full"
              />
            </div>
          </div>
          <div className="w-full flex justify-between items-center">
            <div className="group w-2/5">
              <h3 className="uppercase text-xs tracking-widest group-focus-within:text-white duration-200 text-primary">
                phone number *
              </h3>
              <input
                autoComplete="off"
                type="number"
                name="contact"
                placeholder="enter your phone number"
                value={dataState.contact}
                onChange={handleChange}
                className="border-b group-focus-within:border-white border-b-gray-400  py-2 placeholder:capitalize bg-transparent text-primary focus:outline-none w-full"
              />
            </div>
            <div className="group w-2/5">
              <h3 className="uppercase text-xs tracking-widest group-focus-within:text-white duration-200 text-primary">
                your location *
              </h3>
              <input
                autoComplete="off"
                type="text"
                name="userLocation"
                placeholder="enter your location"
                value={dataState.userLocation}
                onChange={handleChange}
                className="border-b group-focus-within:border-white border-b-gray-400  py-2 placeholder:capitalize bg-transparent text-primary focus:outline-none w-full"
              />
            </div>
          </div>
          <div className="w-full flex justify-between items-center">
            <div className="group w-full">
              <h3 className="uppercase text-xs tracking-widest group-focus-within:text-white duration-200 text-primary">
                message *
              </h3>
              <input
                autoComplete="off"
                type="text"
                name="message"
                placeholder="enter your message"
                value={dataState.message}
                onChange={handleChange}
                className="border-b group-focus-within:border-white border-b-gray-400  py-2 placeholder:capitalize bg-transparent text-primary focus:outline-none w-full"
              />
            </div>
          </div>
          <div className="flex justify-center items-center gap-4">
            <button className="btn-primary border-primary">contact us</button>
            <p className="text-primary">or hit us up at</p>
            <a
              href={`https://api.whatsapp.com/send/?phone=9118811192&text=${locationName}`}
              target="_blank"
            >
              <i className="fa-brands fa-whatsapp text-2xl text-green-600 animate-pulse"></i>
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PopUP;
