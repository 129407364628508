import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const ScrollToTop = () => {
  const [toTop, setToTop] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (window.scrollY >= 800) {
        setToTop(true);
      } else {
        setToTop(false);
      }
    });
  }, []);

  return (
    <div
      className={`fixed bottom-8 right-8 z-[99] text-secondary text-4xl cursor-pointer rounded-full ${
        toTop ? "block" : "hidden"
      }`}
      onClick={() =>
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      }
    >
      <i className="fa-solid fa-circle-arrow-up rounded-full border-2 border-primary animate-pulse"></i>
    </div>
  );
};

export default ScrollToTop;
